import React from "react"
import { RecoilRoot } from "recoil"
import GoTop from "./GoTop"
import "../../assets/scss/style.scss"
import "../../assets/scss/responsive.scss"

const Layout = ({ children }) => {
  return (
    <RecoilRoot>
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </RecoilRoot>
  )
}

export default Layout
