import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import Navbar from "../components/App/Navbar"

const FeatureUnavailable = () => {
  return (
    <Layout>
      <SEO
        title="Feature Unavailable | Oxygen"
        description="This feature is currently unavailable. Please contact us for more information."
      />
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "80vh",
          textAlign: "center",
        }}
      >
        <h1>Feature Unavailable</h1>
        <p>
          This feature is not available now. Please contact us from our Contact
          Us section.
        </p>
      </div>
    </Layout>
  )
}

export default FeatureUnavailable
