import React from "react"
import { useRecoilState } from "recoil"
import { collapsedState } from "../../utils/recoil-atoms"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.svg"

const Navbar = props => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    let elementId = document.getElementById("navbar")
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
      } else {
        elementId.classList.remove("is-sticky")
      }
    })
    window.scrollTo(0, 0)
  })

  function handleContactSelection() {
    setCollapsed(true)
    if (props.clearBrokerState) {
      props.clearBrokerState()
    }
  }

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="primary-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-xl navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  {/* <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Loans<i className='bx bx-chevron-down ml-1'></i>
                    </Link>
                    <ul className="dropdown-menu">
                      
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Home Loans
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/car-loan"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Car Loans
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link to="#" activeClassName="active" className="nav-link">
                      Loans<i className="bx bx-chevron-down ml-1"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/first-home-buyer"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          First Home Buyer
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/property-investment"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Property Investment
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/refinancing-home-loan"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Refinancing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/smsf"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          SMSF
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/car-loan"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Car Loans
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item">
                    <Link to="#" activeClassName="active" className="nav-link">
                      Other Loans<i className="bx bx-chevron-down ml-1"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/car-loan"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Car Loans
                        </Link>
                      </li> */}
                      {/* {`${process.env.ENVIRONMENT}` === "staging" ? ( */}
                      {/* <li className="nav-item">
                        <Link
                          to="/personal-loan"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Personal Loans
                        </Link>
                      </li> */}
                      {/* ) : null} */}
                    {/* </ul>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      to="/calculators"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Calculators
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link to="#" activeClassName="active" className="nav-link">
                      Free Tools<i className="bx bx-chevron-down ml-1"></i>
                    </Link>
                    <ul className="dropdown-menu"> */}
                  {/* <li className="nav-item">
                        <Link
                          to="#"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Free Valuation
                        </Link>
                      </li> */}
                  {/* <li className="nav-item">
                        <Link
                          to="#"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Free Credit check
                        </Link>
                      </li> */}
                  {/* <li className="nav-item">
                        <Link
                          to="/electricity-wizard"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Electricity and Gas Saver
                        </Link>
                      </li> */}
                  {/* {`${process.env.ENVIRONMENT}` === "staging" ? ( */}
                  {/* <li className="nav-item">
                        <a
                          href={`${process.env.BACKEND_API_URL}/createSessionOpen/4e6d2c9a-05b0-48ab-b22c-2217cad7450b`}
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Free Credit Check
                        </a>
                      </li> */}
                  {/* ) : null} */}
                  {/* </ul>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      to="/brokers"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Find a Broker
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Free Tools<i className='bx bx-chevron-down ml-1'></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Free Valuation
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Free Credit check
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/"
                          activeClassName="active"
                          onClick={() => setCollapsed(true)}
                          className="nav-link"
                        >
                          Electricity and Gas Saver
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about-us"
                      activeClassName="active"
                      className="nav-link"
                    >
                      About Oxygen
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      to="/services"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Digital Services
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link
                      to="/news"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      News
                    </Link>
                  </li> */}
                </ul>

                <div className="others-option d-flex align-items-center ml-auto">
                  <div className="option-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      className="option-link"
                      onClick={handleContactSelection}
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div className="option-item mb-3 mb-lg-0">
                    <Link
                      href={`${process.env.GATSBY_OXYGEN_LENDING_WEBSITE}`}
                      activeClassName="active"
                      className="option-link website-switch d-flex align-items-center"
                      onClick={handleContactSelection}
                      target="_blank"
                    >
                      Switch to Lending <i className="bx bx-link-external ml-1 new-tab"></i>
                    </Link>
                  </div>
                  <div className="option-item">
                    <Link
                      to="https://online.originmms.com.au/ib/oxygen"
                      activeClassName="active"
                      onClick={handleContactSelection}
                      className="default-btn primary-btn"
                      target="_blank"
                    >
                      Online Banking
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
